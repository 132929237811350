import "./PreviewCard.css";
import "./PreviewBackCard.css";
import { S3Image } from "aws-amplify-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const PromotionBackCard = (props) => {
  return (
    <div className="backLayer">
      <div className="carouselBackBox">
        <Carousel showThumbs={false} autoPlay={false}>
          {props.business.photos.length > 0 &&
            props.business.photos?.map((item) =>
              item.urlObject ? (
                <div key={item?.key} className="businessPhotoImage">
                  <img src={item.urlObject} alt="" />
                </div>
              ) : (
                <div key={item?.key} className="businessPhotoImage">
                  <S3Image level="protected" imgKey={item?.key} />
                </div>
              )
            )}
        </Carousel>
      </div>
      <div className="cardText">
        <div className="businessName">{props.business.name}</div>
        <div className="promotionBody">{props.business.about}</div>
      </div>
    </div>
  );
};

export default PromotionBackCard;
