import React, { useState, useEffect } from "react";
import "./Admin.css";
import getDiscussionPromptData from "../../api/DiscussionPromptList";
import createNewDiscussionPrompt from "../../api/DiscussionPromptCreate";
import removeDiscussionPrompt from "../../api/DiscussionPromptDelete";
import saveDiscussionPrompt from "../../api/DiscussionPromptUpdate";
import { Button, TextField, Checkbox, Backdrop, CircularProgress } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      color: "#fff",
      zIndex: theme.zIndex.drawer + 1,
    },
  }));

const Admin = () => {
    const classes = useStyles();

    const [prompts, setPrompts] = useState([]);
    const [isBackdropOpen, setIsBackdropOpen] = useState(false);

    useEffect(async () => {
        setIsBackdropOpen(true);
        UpdatePrompts();
    }, [])

    const UpdatePrompts = async () => {
        const discussions = await getDiscussionPromptData();
        const sorted = discussions.sort((first, second) =>
        {
            if (first.createdAt < second.createdAt) {
                return -1;
            }
            if (first.createdAt > second.createdAt) {
                return 1;
            }
            return 0;
            
        } 
        )
        setPrompts(sorted);
        setIsBackdropOpen(false);
    }
    const DeletePrompt = async (id) => {
        setIsBackdropOpen(true);
        
        await removeDiscussionPrompt({id: id})
        UpdatePrompts();
    }

    const CreatePrompt = async () => {
        setIsBackdropOpen(true);
        await createNewDiscussionPrompt({prompt: "Replace this with your question"})
        UpdatePrompts();
    }

    const UpdatePromptText = (id, text) => {
        var currentPrompts = [...prompts]
        var myPrompt = prompts.filter(value => value.id == id)[0]
        myPrompt.prompt = text
        setPrompts(currentPrompts)
    }

    const SaveAll = async () => {
        setIsBackdropOpen(true);
        var toSave = [...prompts]
        for (var item in toSave) {
            delete toSave[item].createdAt;
            delete toSave[item].updatedAt;
            await saveDiscussionPrompt(prompts[item]);
        }
        UpdatePrompts();
    }

    const SetActive = (id, active) => {
        var currentPrompts = [...prompts]
        var myPrompt = prompts.filter(value => value.id == id)[0]
        myPrompt.active = active
        console.log(myPrompt)
        setPrompts(currentPrompts)
    }

    const CreateTreasureBox = async () => {
        var result = await API.post("TreasureAPI", "/treasure", {
            body: {
                active: false,
                promotions: ["1", "2", "3"]
            },
          });
          console.log(result);
    }

    const DeleteTreasureBox = async () => {
        var result = await API.del("TreasureAPI", "/treasure", {
            body: {
                id: "706d294d-b889-4c90-a247-7780ea728004"
            },
          });
          console.log(result);
    }
    const PickTreasureBox = async () => {
        var result = await API.post("TreasureAPI", "/treasure/pickup", {
            body: {
                treasureId: "750b705c-5550-411f-bc61-5f11efff3d47"
            },
          });
          console.log(result);
    }

    const GetTreasureBox = async () => {
        var result = await API.get("TreasureAPI", "/treasure", {
            response: true,
            queryStringParameters: {
              // businessId: businessId,
            }
        });
          console.log(result);
    }

    return (
        <div>
            <button onClick={CreateTreasureBox}>Make me</button>
            <button onClick={DeleteTreasureBox}>Delete me</button>
            <button onClick={PickTreasureBox}>Pick me</button>
            <button onClick={GetTreasureBox}>Get me</button>
        <div>Admin page</div>
        <hr/>
        <div>Conversation prompts</div>
        <div className="admin-prompts-container">
        {prompts.map((value, index) => (
            <div className="admin-prompt-row" key={value.id}>
                <div className="admin-prompt-id">{value.id.substring(value.id.length - 8, value.id.length)}</div>
                <div className="admin-prompt-text"><TextField id="filled-basic" label="Write prompt" type="text" value={value.prompt} onChange={(event) => UpdatePromptText(value.id, event.target.value)}/></div>
                <div className="admin-prompt-active">
                <Checkbox
                    checked={value.active}
                    inputProps={{ 'aria-label': 'Checkbox A' }}
                    onChange={(event, checked) => SetActive(value.id, checked)}
                    />

                </div>
                <div><Button variant="contained" color="secondary" onClick={()=>DeletePrompt(value.id)}>X</Button></div>
            </div>
        ))}
        <Button m="2rem" variant="contained" color="primary" onClick={CreatePrompt}>Create</Button>
        <Button m="2rem" variant="contained" color="primary" onClick={SaveAll}>Save</Button>

        </div>
        <hr />
        <Backdrop className={classes.backdrop} open={isBackdropOpen}>
            <CircularProgress color="inherit" />
        </Backdrop>
        </div>
    )
}

export default Admin;