import Amplify from "aws-amplify";
import { AmplifySignOut } from "@aws-amplify/ui-react";
import awsconfig from "../../aws-exports";
import "./Nav.css";
import birdwireLogo from "../../assets/logo.png";
import { Auth } from "aws-amplify";
import logOutIcon from "../../assets/logout.svg";

Amplify.configure(awsconfig);

const Nav = () => {
  const signOut = () => {
    Auth.signOut()
      .then((data) => console.log("sign out", data))
      .catch((err) => console.log(err));
  };

  return (
    <div className="topBanner">
      <div className="leftBanner">
        <div className="birdwireLogo">
          <img src={birdwireLogo} alt="" className="birdwireLogo" />
        </div>
        <div className="moto">A community driven platform</div>
      </div>
      {/* <div className="nav">
        <ul className="navUl">
          <li className="navList">
            <a href="#" className="navLink">
              Home
            </a>
          </li>
          <li className="navList">
            <a href="#" className="navLink">
              About Us
            </a>
          </li>
          <li className="navList">
            <a href="#" className="navLink">
              Contact Us
            </a>
          </li>
          <li className="navList">
            <button onClick={signOut} className="signOutMobile">
              Log Out
            </button>
          </li>
        </ul>
      </div> */}
      <div className="logDiv">
        <AmplifySignOut />
        {/* <div>
          <img
            src={logOutIcon}
            alt=""
            onClick={signOut}
            className="logOutIcon"
          />
        </div>
        <div className="logOutText">Log Out</div> */}
      </div>
    </div>
  );
};

export default Nav;
