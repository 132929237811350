// import Amplify from "aws-amplify";
import { API } from "aws-amplify";
import { createBusiness } from "../graphql/mutations";
// import awsconfig from "../aws-exports";

// Amplify.configure(awsconfig);

const createUserBusiness = async (business) => {
  const createdBusiness = await API.graphql({
    query: createBusiness,
    variables: { input: business },
  });
  return createdBusiness;
};

export default createUserBusiness;
