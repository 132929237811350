import { API } from "aws-amplify";
import { updateBusiness } from "../graphql/mutations";

const updateUserBusiness = async (business) => {
  const updatedBusiness = await API.graphql({
    query: updateBusiness,
    variables: { input: business },
  });
  return updatedBusiness;
};

export default updateUserBusiness;
