import "./Section4.css";
import mainSection4Image from "../../../assets/main-section-4.png";
import rectangle from "../../../assets/RectangleGreen.png";

const Section4 = () => (
  <div className="main-section-4">
    <div className="main-section-4-text">
      If your promotional deal is running out, we’ll attract last minute buyers
      to your doorsteps by reminding them with an animation.
    </div>

    <div className="main-section-4-image-parent">
      <img src={rectangle} alt="" className="rectangle" />

      <img src={mainSection4Image} alt="" className="main-section-4-image" />
    </div>
  </div>
);

export default Section4;
