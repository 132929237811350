import React, { useState, useEffect } from "react";
import Main from "../../components/main/Main.js";
import Form from "../../components/form/Form.js";
import getBusinessCategoryList from "../../api/BusinessCategoriesGet";
import getUser from "../../api/User";
import getBusinessData from "../../api/BusinessGet";
import getPromotionData from "../../api/PromotionGet";
import awsconfig from "../../aws-exports";
import { API } from "aws-amplify";
import "./Home.css";


const Home = () => {
  const [businessCategories, setBusinessCategories] = useState([{}]);
  const [business, setBusiness] = useState({
    id: "",
    owner: "",
    logo: {
      region: awsconfig.aws_project_region,
      bucket: awsconfig.aws_user_files_s3_bucket,
      key: "",
    },
    name: "",
    businessCategoryId: "1",
    longitude: 0,
    latitude: 0,
    heading: 0,
    contactPerson: "",
    contactEmail: "",
    contactPhone: "",
    address: "",
    openingHours: {
      monday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      tuesday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      wednesday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      thursday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      friday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      saturday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
      sunday: {
        open: false,
        openStart: "",
        openEnd: "",
      },
    },
    about: "",
    photos: [],
    website: ""
  });

  const [promotion, setPromotion] = useState({
    photos: [],
    header: "",
    body: "",
    discountOffer: "",
    startDate: Date.now(),
    expirationDate: "",
    businessId: "",
  });

  async function fetchData() {
    const data = await getBusinessCategoryList();
    console.log("this", data);
    setBusinessCategories(data);
  }

  useEffect(() => {
    fetchData();
  }, []);

  async function initiateBusinessAndPromotion() {
    const data = await getUser();
    setBusiness({
      ...business,
      owner: data.username,
    });
    const businessData = await getBusinessData(data.username);
    console.log(businessData);
    if (businessData.data.listBusinesss.items.length > 0) {
      if (businessData.data.listBusinesss.items[0].openingHours == null) {
        businessData.data.listBusinesss.items[0].openingHours = {
          ...business.openingHours,
        };
      }
      if (businessData.data.listBusinesss.items[0].photos == null) {
        businessData.data.listBusinesss.items[0].photos = [];
      }
      setBusiness({
        ...businessData.data.listBusinesss.items[0],
        businessCategoryId:
          businessData.data.listBusinesss.items[0].category.id,
      });

      document.getElementById("dawa-autocomplete-input").value =
        businessData.data.listBusinesss.items[0].address;

      const promotionData = await getPromotionData(
        businessData.data.listBusinesss.items[0].id
      );
      console.log("promotionaldata", promotionData);
      if (promotionData?.data?.length > 0) {
        const expirationDate = promotionData.data[0].expirationDate;
        if (promotionData.data[0].photos == null) {
          promotionData.data[0].photos = [];
        }
        setPromotion({
          ...promotionData.data[0],
          expirationDate: expirationDate?.split("T")[0],
        });
      }
    }
  }

  async function setupUserDetails() {
    await API.get("birdwireuser", "/user/me", {
      response: true,
      queryStringParameters: {
      }
    });
  }

  useEffect(() => {
    setupUserDetails();
    initiateBusinessAndPromotion();
  }, []);

  return (
    <div>
      <div className="container">
        <Main />
        <div className="formContainer">
          <Form
            businessCategories={businessCategories}
            business={business}
            setBusiness={setBusiness}
            promotion={promotion}
            setPromotion={setPromotion}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
