import Geocode from "react-geocode";

const GeocoderResult = (input, callback) => {
  const YOUR_GOOGLE_MAPS_API_KEY = "AIzaSyBHKVw5gVUVSIXsBpa1LvHH1NAjOsrs3As";
  Geocode.setApiKey(YOUR_GOOGLE_MAPS_API_KEY);

  Geocode.fromAddress(input).then(
    (response) => {
      const { lat, lng } = response.results[0].geometry.location;
      console.log(lat, lng);
      callback({ lat: lat, lng: lng });
    },
    (error) => {
      console.error(error);
      callback({ error: error });
    }
  );
};

export default GeocoderResult;
