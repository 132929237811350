import "./Section3.css";
import mainSection3Image from "../../../assets/main-section-3.png";

const Section3 = () => (
  <div className="main-section-3">
    <div className="main-section-3-text">
      Offer a deal the neighborhood loves and we’ll boost it with visual signals
      to alert everyone, bringing more traffic your way.
    </div>
    <div className="main-section-3-image-parent">
      <img src={mainSection3Image} alt="" className="main-section-3-image" />
    </div>
  </div>
);

export default Section3;
