import { Storage } from "aws-amplify";

const uploadPhoto = async (key, file) => {
  try {
    await Storage.put(key, file, {
      level: "protected",
      // contentType: mimeType,
      // metadata: {albumId: id, photoId}
    });
    return Promise.resolve("success");
  } catch (error) {
    console.log("createPhoto error", error);
    return Promise.reject(error);
  }
};

export default uploadPhoto;
