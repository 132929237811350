import { API } from "aws-amplify";

const getUserInfo = async (userId) => {
  const apiName = "birdwireuser";
  const path = "/user/object/" + userId;
  const myInit = {
    response: true,
    queryStringParameters: {
      // businessId: businessId,
    },
  };
  return await API.get(apiName, path, myInit);
};

export default getUserInfo;
