import { API } from "aws-amplify";
import { listDiscussionPrompts } from "../graphql/queries";

const getDiscussionPromptData = async () => {
  const discussions = await API.graphql({
    query: listDiscussionPrompts,
  });
  console.log(discussions);

  return discussions.data.listDiscussionPrompts.items;
};
//
export default getDiscussionPromptData;
