import "./Form1.css";

const Form1 = (props) => {
  const handleFormChange = (event) => {
    props.setBusiness({
      ...props.business,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <div className="section">
      <div className="title1">get started now</div>
      <div className="title2">Step One</div>
      <div className="title3">
        Who should we contact, if there are any issues?
      </div>
      <div className="line" />
      <label htmlFor="contactPerson">contact person*</label> <br />
      <input
        type="text"
        name="contactPerson"
        className="inputText"
        value={props.business.contactPerson}
        onChange={(e) => handleFormChange(e)}
      />
      <br />
      <label htmlFor="contactEmail">contact email*</label>
      <br />
      <input
        type="email"
        name="contactEmail"
        className="inputText"
        value={props.business.contactEmail}
        onChange={(e) => handleFormChange(e)}
      />
      <br />
      <label htmlFor="contactPhone">contact phone number*</label>
      <br />
      <input
        type="text"
        name="contactPhone"
        className="inputText"
        value={props.business.contactPhone}
        onChange={(e) => handleFormChange(e)}
      />
    </div>
  );
};

export default Form1;
