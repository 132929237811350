// import Amplify from "aws-amplify";
import { API } from "aws-amplify";
import { updateDiscussionPrompt } from "../graphql/mutations";
// import awsconfig from "../aws-exports";

// Amplify.configure(awsconfig);

const saveDiscussionPrompt = async (prompt) => {
  const savedDiscussionPrompt = await API.graphql({
    query: updateDiscussionPrompt,
    variables: { input: prompt },
  });
  console.log(savedDiscussionPrompt);
  return savedDiscussionPrompt.updateDiscussionPrompt;
};

export default saveDiscussionPrompt;
