/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:5a5fd25b-a994-46c7-ad1c-7c08616ebdae",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_XCPUODzkM",
    "aws_user_pools_web_client_id": "183rp80blv2qc1t43fm2lrticv",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://puku3ew7ozglpiemux75wnbzwq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-ir6agh5wjfgdvomksvx2omrjqm",
    "aws_cloud_logic_custom": [
        {
            "name": "birdwireRest",
            "endpoint": "https://06u758m5sj.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireuser",
            "endpoint": "https://o1bcd52dmb.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireLikes",
            "endpoint": "https://p43yp7bl5j.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireDirectMessagesRestAPI",
            "endpoint": "https://v2nriz63mb.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwirePromotion",
            "endpoint": "https://k7hwy0tcn3.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireCoupon",
            "endpoint": "https://twp5bmtfsf.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireCouponCollection",
            "endpoint": "https://mlx863qthb.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdwireVotes",
            "endpoint": "https://nk83i3hgdg.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "TreasureAPI",
            "endpoint": "https://7wzucq1w5e.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "birdReportApi",
            "endpoint": "https://96rtxfnfs7.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "birdwirebb5aab5c337a4ee79ef47022d4787cdb232143-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "userprofile-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdwireuserdb-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdwireLikes-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdwirePromotion-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdwireCoupon-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "couponCollection-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdwireVotes-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "TreasureBox-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "TreasureBoxUser-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "TreasureUser-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "birdReport-prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
