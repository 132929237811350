import { API } from "aws-amplify";
import { listBusinessCategorys } from "../graphql/queries";

const getBusinessCategoryList = async () => {
  const productData = await API.graphql({
    query: listBusinessCategorys,
  });
  const products = await Promise.all(
    productData.data.listBusinessCategorys.items.map(async (product) => {
      return { id: product.id, name: product.name };
    })
  );
  return products;
};

export default getBusinessCategoryList;
