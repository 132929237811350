import "./Section1.css";
import mainSection1Image from "../../../assets/main-section-1.png";

const Section1 = () => (
  <div className="main-section-1">
    <div className="main-section">
      <div className="main-section-1-greeting">Welcome to Birdwire,</div>
      <p className="main-section-1-greeting">
        By filling out this simple form, we’ll have everything needed to put
        your business onto our 3D map and drive more customers your way.
      </p>
      <div className="main-section-1-rest">
        <div className="main-section-1-rest-text">
          <p>
            “Our users enjoy the free flowing chatter that keeps them in tune
            with the neighborhood, but with a switch of a button, can discover
            and connect with local businesses in an exciting new way.
          </p>
          <p>
            Whether it’s an exciting deal that can be shared or a special
            annoucement from their favorite shops, we help customers and
            businesses stay connected and up to date.”
          </p>
        </div>

        <div className="main-section-1-image-parent">
          <img
            src={mainSection1Image}
            alt=""
            className="main-section-1-image"
          />
        </div>
      </div>
    </div>
  </div>
);

export default Section1;
