import { S3Image } from "aws-amplify-react";


const PostView = ({post, poster, reaction}) => {


    const PosterPhoto = () => { 
        return poster.photo ? 
        (
        <S3Image
                level="protected"
                className="poster-photo"
                identityId={poster.userId}
                imgKey={poster.photo.Key}
            />
        ) : 
        (
        <div></div>
        )
    };
    
    const PostAudio = () => { 
    return post.file ? 
        (
        <div className="post-audio">
            This is an audio post, download the app to listen to it :D
        </div>
        ) : 
        (
        <div></div>
        )
    };

    const PostLikes = () => {
    return reaction.like && reaction.like != 0 ? 
        (
        <div className="reaction-container">
            <div className="heart-shape"></div>
            <div className="reaction-number">{reaction.like}</div>
        </div>
        ) : 
        (
        <div></div>
        )
    }

    const PostPhoto = () => { 
    return post.photo ? 
        (
        <S3Image
                level="public"
                className="post-photo"
                identityId={poster.userId}
                imgKey={post.photo.key}
            />
        ) : 
        (
        <div></div>
        )
    };
    

    return (
        <div className="post-container">

          <div className="poster-container">
            <div className="poster-photo-container">
            <PosterPhoto />
            </div>
            <div className="poster-name">
              {poster.name}
            </div>
          </div>
          <div className="post-text">{post.body}</div>
          <PostPhoto />
          <PostAudio />
          <PostLikes />
        </div>
        
   
    )
}

export default PostView;