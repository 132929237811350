/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createBillboard = /* GraphQL */ `
  mutation CreateBillboard(
    $input: CreateBillboardInput!
    $condition: ModelBillboardConditionInput
  ) {
    createBillboard(input: $input, condition: $condition) {
      id
      owner
      headline
      longitude
      latitude
      text
      photos {
        nextToken
      }
      messages {
        nextToken
      }
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBillboard = /* GraphQL */ `
  mutation UpdateBillboard(
    $input: UpdateBillboardInput!
    $condition: ModelBillboardConditionInput
  ) {
    updateBillboard(input: $input, condition: $condition) {
      id
      owner
      headline
      longitude
      latitude
      text
      photos {
        nextToken
      }
      messages {
        nextToken
      }
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBillboard = /* GraphQL */ `
  mutation DeleteBillboard(
    $input: DeleteBillboardInput!
    $condition: ModelBillboardConditionInput
  ) {
    deleteBillboard(input: $input, condition: $condition) {
      id
      owner
      headline
      longitude
      latitude
      text
      photos {
        nextToken
      }
      messages {
        nextToken
      }
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPhoto = /* GraphQL */ `
  mutation CreatePhoto(
    $input: CreatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    createPhoto(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      billboard {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
    }
  }
`;
export const updatePhoto = /* GraphQL */ `
  mutation UpdatePhoto(
    $input: UpdatePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    updatePhoto(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      billboard {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
    }
  }
`;
export const deletePhoto = /* GraphQL */ `
  mutation DeletePhoto(
    $input: DeletePhotoInput!
    $condition: ModelPhotoConditionInput
  ) {
    deletePhoto(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
      billboard {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
    }
  }
`;
export const createBillboardCategory = /* GraphQL */ `
  mutation CreateBillboardCategory(
    $input: CreateBillboardCategoryInput!
    $condition: ModelBillboardCategoryConditionInput
  ) {
    createBillboardCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      billboard {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBillboardCategory = /* GraphQL */ `
  mutation UpdateBillboardCategory(
    $input: UpdateBillboardCategoryInput!
    $condition: ModelBillboardCategoryConditionInput
  ) {
    updateBillboardCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      billboard {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBillboardCategory = /* GraphQL */ `
  mutation DeleteBillboardCategory(
    $input: DeleteBillboardCategoryInput!
    $condition: ModelBillboardCategoryConditionInput
  ) {
    deleteBillboardCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      billboard {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createBillboardPublicChat = /* GraphQL */ `
  mutation CreateBillboardPublicChat(
    $input: CreateBillboardPublicChatInput!
    $condition: ModelBillboardPublicChatConditionInput
  ) {
    createBillboardPublicChat(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      billboardId {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      text
    }
  }
`;
export const updateBillboardPublicChat = /* GraphQL */ `
  mutation UpdateBillboardPublicChat(
    $input: UpdateBillboardPublicChatInput!
    $condition: ModelBillboardPublicChatConditionInput
  ) {
    updateBillboardPublicChat(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      billboardId {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      text
    }
  }
`;
export const deleteBillboardPublicChat = /* GraphQL */ `
  mutation DeleteBillboardPublicChat(
    $input: DeleteBillboardPublicChatInput!
    $condition: ModelBillboardPublicChatConditionInput
  ) {
    deleteBillboardPublicChat(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      billboardId {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      text
    }
  }
`;
export const createNeighborNewsFeed = /* GraphQL */ `
  mutation CreateNeighborNewsFeed(
    $input: CreateNeighborNewsFeedInput!
    $condition: ModelNeighborNewsFeedConditionInput
  ) {
    createNeighborNewsFeed(input: $input, condition: $condition) {
      id
      owner
      name
      longitude
      latitude
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNeighborNewsFeed = /* GraphQL */ `
  mutation UpdateNeighborNewsFeed(
    $input: UpdateNeighborNewsFeedInput!
    $condition: ModelNeighborNewsFeedConditionInput
  ) {
    updateNeighborNewsFeed(input: $input, condition: $condition) {
      id
      owner
      name
      longitude
      latitude
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNeighborNewsFeed = /* GraphQL */ `
  mutation DeleteNeighborNewsFeed(
    $input: DeleteNeighborNewsFeedInput!
    $condition: ModelNeighborNewsFeedConditionInput
  ) {
    deleteNeighborNewsFeed(input: $input, condition: $condition) {
      id
      owner
      name
      longitude
      latitude
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createNeighborNewsPost = /* GraphQL */ `
  mutation CreateNeighborNewsPost(
    $input: CreateNeighborNewsPostInput!
    $condition: ModelNeighborNewsPostConditionInput
  ) {
    createNeighborNewsPost(input: $input, condition: $condition) {
      id
      owner
      createdAt
      newsFeedId {
        id
        owner
        name
        longitude
        latitude
        createdAt
        updatedAt
      }
      headline
      text
      photos {
        region
        bucket
        key
      }
      longitude
      latitude
      updatedAt
    }
  }
`;
export const updateNeighborNewsPost = /* GraphQL */ `
  mutation UpdateNeighborNewsPost(
    $input: UpdateNeighborNewsPostInput!
    $condition: ModelNeighborNewsPostConditionInput
  ) {
    updateNeighborNewsPost(input: $input, condition: $condition) {
      id
      owner
      createdAt
      newsFeedId {
        id
        owner
        name
        longitude
        latitude
        createdAt
        updatedAt
      }
      headline
      text
      photos {
        region
        bucket
        key
      }
      longitude
      latitude
      updatedAt
    }
  }
`;
export const deleteNeighborNewsPost = /* GraphQL */ `
  mutation DeleteNeighborNewsPost(
    $input: DeleteNeighborNewsPostInput!
    $condition: ModelNeighborNewsPostConditionInput
  ) {
    deleteNeighborNewsPost(input: $input, condition: $condition) {
      id
      owner
      createdAt
      newsFeedId {
        id
        owner
        name
        longitude
        latitude
        createdAt
        updatedAt
      }
      headline
      text
      photos {
        region
        bucket
        key
      }
      longitude
      latitude
      updatedAt
    }
  }
`;
export const createDirectMessage = /* GraphQL */ `
  mutation CreateDirectMessage(
    $input: CreateDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    createDirectMessage(input: $input, condition: $condition) {
      id
      owner
      receiver
      createdAt
      updatedAt
      parent {
        id
        owner
        receiver
        createdAt
        updatedAt
        text
      }
      children {
        nextToken
      }
      text
    }
  }
`;
export const updateDirectMessage = /* GraphQL */ `
  mutation UpdateDirectMessage(
    $input: UpdateDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    updateDirectMessage(input: $input, condition: $condition) {
      id
      owner
      receiver
      createdAt
      updatedAt
      parent {
        id
        owner
        receiver
        createdAt
        updatedAt
        text
      }
      children {
        nextToken
      }
      text
    }
  }
`;
export const deleteDirectMessage = /* GraphQL */ `
  mutation DeleteDirectMessage(
    $input: DeleteDirectMessageInput!
    $condition: ModelDirectMessageConditionInput
  ) {
    deleteDirectMessage(input: $input, condition: $condition) {
      id
      owner
      receiver
      createdAt
      updatedAt
      parent {
        id
        owner
        receiver
        createdAt
        updatedAt
        text
      }
      children {
        nextToken
      }
      text
    }
  }
`;
export const createBusiness = /* GraphQL */ `
  mutation CreateBusiness(
    $input: CreateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    createBusiness(input: $input, condition: $condition) {
      id
      owner
      logo {
        region
        bucket
        key
      }
      name
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      longitude
      latitude
      heading
      contactPerson
      contactEmail
      contactPhone
      address
      about
      photos {
        region
        bucket
        key
      }
      website
      createdAt
      updatedAt
    }
  }
`;
export const updateBusiness = /* GraphQL */ `
  mutation UpdateBusiness(
    $input: UpdateBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    updateBusiness(input: $input, condition: $condition) {
      id
      owner
      logo {
        region
        bucket
        key
      }
      name
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      longitude
      latitude
      heading
      contactPerson
      contactEmail
      contactPhone
      address
      about
      photos {
        region
        bucket
        key
      }
      website
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusiness = /* GraphQL */ `
  mutation DeleteBusiness(
    $input: DeleteBusinessInput!
    $condition: ModelBusinessConditionInput
  ) {
    deleteBusiness(input: $input, condition: $condition) {
      id
      owner
      logo {
        region
        bucket
        key
      }
      name
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      longitude
      latitude
      heading
      contactPerson
      contactEmail
      contactPhone
      address
      about
      photos {
        region
        bucket
        key
      }
      website
      createdAt
      updatedAt
    }
  }
`;
export const createBusinessCategory = /* GraphQL */ `
  mutation CreateBusinessCategory(
    $input: CreateBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    createBusinessCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      business {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateBusinessCategory = /* GraphQL */ `
  mutation UpdateBusinessCategory(
    $input: UpdateBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    updateBusinessCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      business {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteBusinessCategory = /* GraphQL */ `
  mutation DeleteBusinessCategory(
    $input: DeleteBusinessCategoryInput!
    $condition: ModelBusinessCategoryConditionInput
  ) {
    deleteBusinessCategory(input: $input, condition: $condition) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      business {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createThreadObject = /* GraphQL */ `
  mutation CreateThreadObject(
    $input: CreateThreadObjectInput!
    $condition: ModelThreadObjectConditionInput
  ) {
    createThreadObject(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      file {
        region
        bucket
        key
      }
      photo {
        region
        bucket
        key
      }
      body
      longitude
      latitude
      threadType
      anonymous
      rootThreadName
      neighborhood
      parentId
      reports {
        nextToken
      }
      parent {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      children {
        nextToken
      }
    }
  }
`;
export const updateThreadObject = /* GraphQL */ `
  mutation UpdateThreadObject(
    $input: UpdateThreadObjectInput!
    $condition: ModelThreadObjectConditionInput
  ) {
    updateThreadObject(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      file {
        region
        bucket
        key
      }
      photo {
        region
        bucket
        key
      }
      body
      longitude
      latitude
      threadType
      anonymous
      rootThreadName
      neighborhood
      parentId
      reports {
        nextToken
      }
      parent {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      children {
        nextToken
      }
    }
  }
`;
export const deleteThreadObject = /* GraphQL */ `
  mutation DeleteThreadObject(
    $input: DeleteThreadObjectInput!
    $condition: ModelThreadObjectConditionInput
  ) {
    deleteThreadObject(input: $input, condition: $condition) {
      id
      owner
      createdAt
      updatedAt
      file {
        region
        bucket
        key
      }
      photo {
        region
        bucket
        key
      }
      body
      longitude
      latitude
      threadType
      anonymous
      rootThreadName
      neighborhood
      parentId
      reports {
        nextToken
      }
      parent {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      children {
        nextToken
      }
    }
  }
`;
export const createReportContent = /* GraphQL */ `
  mutation CreateReportContent(
    $input: CreateReportContentInput!
    $condition: ModelReportContentConditionInput
  ) {
    createReportContent(input: $input, condition: $condition) {
      id
      owner
      contentType
      createdAt
      updatedAt
      reportType
      content {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
    }
  }
`;
export const updateReportContent = /* GraphQL */ `
  mutation UpdateReportContent(
    $input: UpdateReportContentInput!
    $condition: ModelReportContentConditionInput
  ) {
    updateReportContent(input: $input, condition: $condition) {
      id
      owner
      contentType
      createdAt
      updatedAt
      reportType
      content {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
    }
  }
`;
export const deleteReportContent = /* GraphQL */ `
  mutation DeleteReportContent(
    $input: DeleteReportContentInput!
    $condition: ModelReportContentConditionInput
  ) {
    deleteReportContent(input: $input, condition: $condition) {
      id
      owner
      contentType
      createdAt
      updatedAt
      reportType
      content {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
    }
  }
`;
export const createDiscussionPrompt = /* GraphQL */ `
  mutation CreateDiscussionPrompt(
    $input: CreateDiscussionPromptInput!
    $condition: ModelDiscussionPromptConditionInput
  ) {
    createDiscussionPrompt(input: $input, condition: $condition) {
      id
      prompt
      active
      createdAt
      updatedAt
    }
  }
`;
export const updateDiscussionPrompt = /* GraphQL */ `
  mutation UpdateDiscussionPrompt(
    $input: UpdateDiscussionPromptInput!
    $condition: ModelDiscussionPromptConditionInput
  ) {
    updateDiscussionPrompt(input: $input, condition: $condition) {
      id
      prompt
      active
      createdAt
      updatedAt
    }
  }
`;
export const deleteDiscussionPrompt = /* GraphQL */ `
  mutation DeleteDiscussionPrompt(
    $input: DeleteDiscussionPromptInput!
    $condition: ModelDiscussionPromptConditionInput
  ) {
    deleteDiscussionPrompt(input: $input, condition: $condition) {
      id
      prompt
      active
      createdAt
      updatedAt
    }
  }
`;
