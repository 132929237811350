import "./Main.css";
import Section1 from "./section1/Section1.js";
import Section2 from "./section2/Section2.js";
import Section3 from "./section3/Section3.js";
import Section4 from "./section4/Section4.js";
import Section5 from "./section5/Section5.js";

const Main = () => (
  <div className="main-container">
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
    <Section5 />
  </div>
);

export default Main;
