var dawaAutocomplete2 = require("dawa-autocomplete2");

const addressAutoComplete = (callback) => {
  var inputElm = document.getElementById("dawa-autocomplete-input");
  var component = dawaAutocomplete2.dawaAutocomplete(inputElm, {
    select: function (selected) {
      console.log("Valgt adresse: " + selected.tekst);
      callback(selected.tekst);
    },
  });
  return component;
};

export default addressAutoComplete;
