import "./Form2.css";

const BusinessOpeningHours = (props) => {
  const handleDay = (event) => {
    const [day, property] = event.target.name.split(".");
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    var newOpeningHours = { ...props.business.openingHours };
    var newDay = { ...newOpeningHours[day], [property]: value };
    newOpeningHours[day] = newDay;
    props.setBusiness({ ...props.business, openingHours: newOpeningHours });
  };

  return (
    <>
      <label htmlFor="timeTable">choose your open days and hours</label>
      <table className="timeTable">
        <tbody>
          <tr>
            <th></th>
            <th></th>
            <th>From</th>
            <th>To</th>
          </tr>
          <tr>
            <td>Monday</td>
            <td>
              <input
                type="checkbox"
                className="checkbox"
                name="monday.open"
                value={props.business.openingHours.monday.open}
                checked={props.business.openingHours.monday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"monday.openStart"}
                className="time"
                value={props.business.openingHours.monday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"monday.openEnd"}
                className="time"
                value={props.business.openingHours.monday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Tuesday</td>
            <td>
              <input
                type="checkbox"
                name="tuesday.open"
                className="checkbox"
                value={props.business.openingHours.tuesday.open}
                checked={props.business.openingHours.tuesday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"tuesday.openStart"}
                className="time"
                value={props.business.openingHours.tuesday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"tuesday.openEnd"}
                className="time"
                value={props.business.openingHours.tuesday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Wednesday</td>
            <td>
              <input
                type="checkbox"
                name="wednesday.open"
                className="checkbox"
                value={props.business.openingHours.wednesday.open}
                checked={props.business.openingHours.wednesday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"wednesday.openStart"}
                className="time"
                value={props.business.openingHours.wednesday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"wednesday.openEnd"}
                className="time"
                value={props.business.openingHours.wednesday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Thursday</td>
            <td>
              <input
                type="checkbox"
                name="thursday.open"
                className="checkbox"
                value={props.business.openingHours.thursday.open}
                checked={props.business.openingHours.thursday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"thursday.openStart"}
                className="time"
                value={props.business.openingHours.thursday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"thursday.openEnd"}
                className="time"
                value={props.business.openingHours.thursday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Friday</td>
            <td>
              <input
                type="checkbox"
                name="friday.open"
                className="checkbox"
                value={props.business.openingHours.friday.open}
                checked={props.business.openingHours.friday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"friday.openStart"}
                className="time"
                value={props.business.openingHours.friday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"friday.openEnd"}
                className="time"
                value={props.business.openingHours.friday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Saturday</td>
            <td>
              <input
                type="checkbox"
                name="saturday.open"
                className="checkbox"
                value={props.business.openingHours.saturday.open}
                checked={props.business.openingHours.saturday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"saturday.openStart"}
                className="time"
                value={props.business.openingHours.saturday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"saturday.openEnd"}
                className="time"
                value={props.business.openingHours.saturday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
          <tr>
            <td>Sunday</td>
            <td>
              <input
                type="checkbox"
                name="sunday.open"
                className="checkbox"
                value={props.business.openingHours.sunday.open}
                checked={props.business.openingHours.sunday.open}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"sunday.openStart"}
                className="time"
                value={props.business.openingHours.sunday.openStart}
                onChange={(e) => handleDay(e)}
              />
            </td>
            <td>
              <input
                type="time"
                name={"sunday.openEnd"}
                className="time"
                value={props.business.openingHours.sunday.openEnd}
                onChange={(e) => handleDay(e)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default BusinessOpeningHours;
