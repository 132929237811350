import { API } from "aws-amplify";
// import { getBusiness, listBusinesss } from "../../graphql/queries";

// const getBusinessData = async (userName) => {
//   const business = await API.graphql({
//     query: getBusiness,
//     variables: { owner: userName },
//   });
//   console.log(business);

//   return business.data.getBusiness;
// };

// export default getBusinessData;

const listBusinesss = /* GraphQL */ `
  query ListBusinesss(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        longitude
        latitude
        heading
        category {
          id
        }
        logo {
          bucket
          key
          region
        }
        contactPerson
        contactEmail
        contactPhone
        address
        openingHours {
          monday {
            open
            openStart
            openEnd
          }
          tuesday {
            open
            openStart
            openEnd
          }
          wednesday {
            open
            openStart
            openEnd
          }
          thursday {
            open
            openStart
            openEnd
          }
          friday {
            open
            openStart
            openEnd
          }
          saturday {
            open
            openStart
            openEnd
          }
          sunday {
            open
            openStart
            openEnd
          }
        }
        about
        photos {
          bucket
          region
          key
        }
        createdAt
        updatedAt
        website
      }
      nextToken
    }
  }
`;

const getBusinessData = async (userName) => {
  console.log({
    owner: {
      eq: userName,
    },
  });
  const businessList = await API.graphql({
    query: listBusinesss,
    variables: {
      filter: {
        owner: {
          eq: userName,
        },
      },
    },
  });

  return businessList;
};

export default getBusinessData;
