import "./Section2.css";
import mainSection2Image from "../../../assets/main-section-2.png";

const Section2 = () => (
  <div className="main-section-2">
    <div className="main-section-2-text">
      If someone finds your promotional offer attractive, they can pull it over
      to the community side and share it, helping to further boost your
      visibility.
    </div>
    <div className="main-section-2-image-parent">
      <img src={mainSection2Image} alt="" className="main-section-2-image" />
    </div>
  </div>
);

export default Section2;
