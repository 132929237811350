/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listAdminDiscussionPrompts = /* GraphQL */ `
  query ListAdminDiscussionPrompts {
    listAdminDiscussionPrompts {
      id
      prompt
      active
      createdAt
      updatedAt
    }
  }
`;
export const getBillboard = /* GraphQL */ `
  query GetBillboard($id: ID!) {
    getBillboard(id: $id) {
      id
      owner
      headline
      longitude
      latitude
      text
      photos {
        nextToken
      }
      messages {
        nextToken
      }
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBillboards = /* GraphQL */ `
  query ListBillboards(
    $filter: ModelBillboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPhoto = /* GraphQL */ `
  query GetPhoto($id: ID!) {
    getPhoto(id: $id) {
      id
      createdAt
      updatedAt
      billboard {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      fullsize {
        region
        bucket
        key
      }
      thumbnail {
        region
        bucket
        key
      }
      contentType
      height
      width
      size
      owner
    }
  }
`;
export const listPhotos = /* GraphQL */ `
  query ListPhotos(
    $filter: ModelPhotoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        createdAt
        updatedAt
        contentType
        height
        width
        size
        owner
      }
      nextToken
    }
  }
`;
export const getBillboardCategory = /* GraphQL */ `
  query GetBillboardCategory($id: ID!) {
    getBillboardCategory(id: $id) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      billboard {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBillboardCategorys = /* GraphQL */ `
  query ListBillboardCategorys(
    $filter: ModelBillboardCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillboardCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBillboardPublicChat = /* GraphQL */ `
  query GetBillboardPublicChat($id: ID!) {
    getBillboardPublicChat(id: $id) {
      id
      owner
      createdAt
      updatedAt
      billboardId {
        id
        owner
        headline
        longitude
        latitude
        text
        createdAt
        updatedAt
      }
      text
    }
  }
`;
export const listBillboardPublicChats = /* GraphQL */ `
  query ListBillboardPublicChats(
    $filter: ModelBillboardPublicChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillboardPublicChats(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        text
      }
      nextToken
    }
  }
`;
export const getNeighborNewsFeed = /* GraphQL */ `
  query GetNeighborNewsFeed($id: ID!) {
    getNeighborNewsFeed(id: $id) {
      id
      owner
      name
      longitude
      latitude
      posts {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNeighborNewsFeeds = /* GraphQL */ `
  query ListNeighborNewsFeeds(
    $filter: ModelNeighborNewsFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNeighborNewsFeeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        longitude
        latitude
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNeighborNewsPost = /* GraphQL */ `
  query GetNeighborNewsPost($id: ID!) {
    getNeighborNewsPost(id: $id) {
      id
      owner
      createdAt
      newsFeedId {
        id
        owner
        name
        longitude
        latitude
        createdAt
        updatedAt
      }
      headline
      text
      photos {
        region
        bucket
        key
      }
      longitude
      latitude
      updatedAt
    }
  }
`;
export const listNeighborNewsPosts = /* GraphQL */ `
  query ListNeighborNewsPosts(
    $filter: ModelNeighborNewsPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNeighborNewsPosts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        headline
        text
        longitude
        latitude
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDirectMessage = /* GraphQL */ `
  query GetDirectMessage($id: ID!) {
    getDirectMessage(id: $id) {
      id
      owner
      receiver
      createdAt
      updatedAt
      parent {
        id
        owner
        receiver
        createdAt
        updatedAt
        text
      }
      children {
        nextToken
      }
      text
    }
  }
`;
export const listDirectMessages = /* GraphQL */ `
  query ListDirectMessages(
    $filter: ModelDirectMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDirectMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        receiver
        createdAt
        updatedAt
        text
      }
      nextToken
    }
  }
`;
export const getBusiness = /* GraphQL */ `
  query GetBusiness($id: ID!) {
    getBusiness(id: $id) {
      id
      owner
      logo {
        region
        bucket
        key
      }
      name
      category {
        id
        owner
        name
        createdAt
        updatedAt
      }
      longitude
      latitude
      heading
      contactPerson
      contactEmail
      contactPhone
      address
      about
      photos {
        region
        bucket
        key
      }
      website
      createdAt
      updatedAt
    }
  }
`;
export const listBusinesss = /* GraphQL */ `
  query ListBusinesss(
    $filter: ModelBusinessFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinesss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        longitude
        latitude
        heading
        contactPerson
        contactEmail
        contactPhone
        address
        about
        website
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBusinessCategory = /* GraphQL */ `
  query GetBusinessCategory($id: ID!) {
    getBusinessCategory(id: $id) {
      id
      owner
      name
      icon {
        region
        bucket
        key
      }
      business {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listBusinessCategorys = /* GraphQL */ `
  query ListBusinessCategorys(
    $filter: ModelBusinessCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBusinessCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReportContent = /* GraphQL */ `
  query GetReportContent($id: ID!) {
    getReportContent(id: $id) {
      id
      owner
      contentType
      createdAt
      updatedAt
      reportType
      content {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
    }
  }
`;
export const listReportContents = /* GraphQL */ `
  query ListReportContents(
    $filter: ModelReportContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReportContents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        contentType
        createdAt
        updatedAt
        reportType
      }
      nextToken
    }
  }
`;
export const getDiscussionPrompt = /* GraphQL */ `
  query GetDiscussionPrompt($id: ID!) {
    getDiscussionPrompt(id: $id) {
      id
      prompt
      active
      createdAt
      updatedAt
    }
  }
`;
export const listDiscussionPrompts = /* GraphQL */ `
  query ListDiscussionPrompts(
    $filter: ModelDiscussionPromptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscussionPrompts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        prompt
        active
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getThreadObject = /* GraphQL */ `
  query GetThreadObject($id: ID!) {
    getThreadObject(id: $id) {
      id
      owner
      createdAt
      updatedAt
      file {
        region
        bucket
        key
      }
      photo {
        region
        bucket
        key
      }
      body
      longitude
      latitude
      threadType
      anonymous
      rootThreadName
      neighborhood
      parentId
      reports {
        nextToken
      }
      parent {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      children {
        nextToken
      }
    }
  }
`;
export const listThreadObjects = /* GraphQL */ `
  query ListThreadObjects(
    $filter: ModelThreadObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThreadObjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      nextToken
    }
  }
`;
export const childrenByCreationDate = /* GraphQL */ `
  query ChildrenByCreationDate(
    $parentId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThreadObjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    childrenByCreationDate(
      parentId: $parentId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        createdAt
        updatedAt
        body
        longitude
        latitude
        threadType
        anonymous
        rootThreadName
        neighborhood
        parentId
      }
      nextToken
    }
  }
`;
