import { API } from "aws-amplify";

const getLikes = async (featureId) => {
  const apiName = "birdwireLikes";
  const path = "/likes/threadObject/" + featureId;
  const myInit = {
    response: true,
    queryStringParameters: {
      // businessId: businessId,
    },
  };
  return await API.get(apiName, path, myInit);
};

export default getLikes;
