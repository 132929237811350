import { API } from "aws-amplify";

const getPromotionData = async (businessId) => {
  const apiName = "birdwireCoupon";
  const path = "/coupon/business/" + businessId;
  const myInit = {
    response: true,
    queryStringParameters: {
      businessId: businessId,
    },
  };
  return await API.get(apiName, path, myInit);
};

export default getPromotionData;
