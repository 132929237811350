import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="contactColumn">
        <div className="footerContactTitle">Contact Us </div>

        <div>{/* <span className="footerLine1" /> */}</div>

        <div className="contact">
          <span>Anders Ovesen,</span>
          <span>anders@birdwire.dk,</span>
          <span>+45 6015 7333,</span>
          <span>Copenhagen</span>
        </div>
        <div className="copyright">
          <span>Copyright © 2021 Birdwire.</span>
          <span>All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
