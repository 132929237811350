// import Amplify from "aws-amplify";
import { API } from "aws-amplify";
import { createDiscussionPrompt } from "../graphql/mutations";
// import awsconfig from "../aws-exports";

// Amplify.configure(awsconfig);

const createNewDiscussionPrompt = async (prompt) => {
  const createdDiscussionPrompt = await API.graphql({
    query: createDiscussionPrompt,
    variables: { input: prompt },
  });
  console.log(createdDiscussionPrompt);
  return createdDiscussionPrompt.createDiscussionPrompt;
};

export default createNewDiscussionPrompt;
