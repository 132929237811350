import React from "react";
import Home from "./pages/home/Home.js";
import Admin from "./pages/admin/Admin.js";
import Nav from "./components/nav/Nav.js";
import Footer from "./components/footer/Footer.js";
import PublicPostView from "./components/public/postView/PublicPostView";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";

import { withAuthenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import "./App.css";
import { AmplifyAuthenticator, AmplifySignOut } from "@aws-amplify/ui-react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import CookieConsent from "react-cookie-consent";


Amplify.configure(awsconfig);


const StartApp = () => {
  return (
    <div>
      <AuthCompo />
      <CookieConsent
        buttonText="Close"
        style={{ background: "#0F1419", fontSize:"15px", fontWeight: 400, lineHeight: "55px" }}
        buttonStyle={{ backgroundColor: "#0F1419", border: "1px solid white", borderRadius: 34, color:"white", fontSize: "13px" }}
      >
        
      By using Birdwire’s services you agree to our &nbsp;
      <a className="privacy-policy-url" href="https://www.gdprprivacypolicy.net/live.php?token=UVT3zavvYaaBULlt9KY8GPTNnNMXu8cx">Cookies Use and our Privacy Policy.</a>
  
      </CookieConsent>
    </div>
  )
}

const App = () => (
  <div>
    <Nav />
    <Navigation />
    <Footer />
  </div>
);


const AuthCompo = withAuthenticator(App, true);



const ContextNavigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/public/post" children={<PublicPostView />} />
        <Route path="/" children={<StartApp />} />
      </Switch>
  </Router>
  )
}


const Navigation = () => {
  return (
    <Router>
      <Switch>
        <Route path="/admin" children={<Admin />} />
        <Route path="/" children={<Home />} />
      </Switch>
  </Router>
  )
}

export default ContextNavigation;
