import "./PreviewCard.css";
import { S3Image } from "aws-amplify-react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const PromotionCard = (props) => {
  return (
    <div className="businessPromotionCard">
      <div className="whiteLayer">
        <div className="logoImage">
          {props.business.logo?.urlObject ? (
            <img
              className="localImage"
              src={props.business.logo.urlObject}
              alt=""
            />
          ) : (
            <S3Image
              className="preview-logo"
              level="protected"
              imgKey={props.business.logo?.key}
            />
          )}
        </div>
        <div className="businessName">{props.business.name}</div>
        <div className="carouselBox">
          <Carousel showThumbs={false}>
            {props.promotion.photos.length > 0 &&
              props.promotion.photos?.map((item) =>
                item.urlObject ? (
                  <div key={"__" + item?.key} className="cardImage">
                    <img src={item.urlObject} alt="" />
                  </div>
                ) : (
                  <div key={"__" + item?.key} className="cardImage">
                    <S3Image level="protected" imgKey={item?.key} />
                  </div>
                )
              )}
          </Carousel>
        </div>
        <div className="cardText">
          <div className="promotionHeader">{props.promotion.header}</div>
          {props.promotion.discountOffer ? (
            <div className="discount">{props.promotion.discountOffer}</div>
          ) : null}

          {props.promotion.expirationDate === "" ? null : (
            <div>Expires {props.promotion.expirationDate}</div>
          )}
          <div className="promotionBody">{props.promotion.body}</div>
          <h4>Opening Hours</h4>
          <table className="cardOpeningHours">
            <tbody>
              {props.business.openingHours?.monday.open ? (
                <tr>
                  <td className="day">monday</td>
                  <td>
                    {props.business.openingHours.monday.openStart}-
                    {props.business.openingHours.monday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.tuesday.open ? (
                <tr>
                  <td className="day">tuesday</td>
                  <td>
                    {props.business.openingHours.tuesday.openStart}-
                    {props.business.openingHours.tuesday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.wednesday.open ? (
                <tr>
                  <td className="day">wednesday</td>
                  <td>
                    {props.business.openingHours.wednesday.openStart}-
                    {props.business.openingHours.wednesday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.thursday.open ? (
                <tr>
                  <td className="day">thursday</td>
                  <td>
                    {props.business.openingHours.thursday.openStart}-
                    {props.business.openingHours.thursday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.friday.open ? (
                <tr>
                  <td className="day">friday</td>
                  <td>
                    {props.business.openingHours.friday.openStart}-
                    {props.business.openingHours.friday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.saturday.open ? (
                <tr>
                  <td className="day">saturday</td>
                  <td>
                    {props.business.openingHours.saturday.openStart}-
                    {props.business.openingHours.saturday.openEnd}
                  </td>
                </tr>
              ) : null}
              {props.business.openingHours?.sunday.open ? (
                <tr>
                  <td className="day">sunday</td>
                  <td>
                    {props.business.openingHours.sunday.openStart}-
                    {props.business.openingHours.sunday.openEnd}
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
          <h4>Address</h4>
          <div className="address">{props.business.address}</div>
        </div>
      </div>
    </div>
  );
};

export default PromotionCard;
