import "./Form2.css";
import "./DawaAutocomplete.css";
import { useEffect } from "react";
import addressAutoComplete from "../../../lib/autocomplete/DawaAutocomplete";
import GeocoderResult from "../../../lib/autocomplete/GeocoderInput.js";
import BusinessOpeningHours from "./OpeningHours";
import awsconfig from "../../../aws-exports";
import { S3Image } from "aws-amplify-react";
import xButton from "../../../assets/close-icon.svg";
// import uploadIcon from "../../../assets/upload-image-icon.png";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const Form2 = (props) => {
  useEffect(() => {
    const component = addressAutoComplete((address) => {
      const geoCodeResult = GeocoderResult(address, (latlng) => {
        if (latlng.error) {
          alert(latlng.error);
        } else {
          props.setBusiness({
            ...props.business,
            address: address,
            longitude: latlng.lng,
            latitude: latlng.lat,
          });
        }
      });
      return () => {
        geoCodeResult.destroy();
      };
    });
    return () => {
      component.destroy();
    };
  });

  const handleUploadLogo = (event) => {
    const file = event.target.files[0];
    if (file.size > 128000) {
      alert("Image is more than 128kb");
      return;
    }

    const eventValue = URL.createObjectURL(file);
    const photoId = uuidv4();
    const extension = file?.name.split(".")[1];
    const key = `businessLogo/${photoId}.${extension.toLowerCase()}`;

    props.setUploadLogo({
      urlObject: eventValue,
      file: file,
      key: key,
      extension: extension,
    });

    props.setBusiness({
      ...props.business,
      logo: {
        region: awsconfig.aws_project_region,
        bucket: awsconfig.aws_user_files_s3_bucket,
        key: key,
        urlObject: eventValue,
      },
    });

    // var reader = new FileReader();
    // reader.readAsDataURL(file);
    // reader.onload = (event) => {
    //   var image = new Image();
    //   image.src = event.target.result;
    //   image.onload = function () {
    //     props.setIsLandscape(this.width > this.height);
    //   };
    // };
  };

  const handleBusinessForm = (event) => {
    props.setBusiness({
      ...props.business,
      [event.target.name]: event.target.value,
    });
  };

  const handleUploadPhotos = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 512000) {
        alert("Image is more than 0.5MB");
        return;
      }
      const eventValue = URL.createObjectURL(file);
      const photoId = uuidv4();
      const extension = file?.name.split(".")[1];
      const key = `BusinessPhotos/${photoId}.${extension.toLowerCase()}`;

      props.setBusiness({
        ...props.business,
        photos: [
          ...props.business.photos,
          {
            region: awsconfig.aws_project_region,
            bucket: awsconfig.aws_user_files_s3_bucket,
            key: key,
            urlObject: eventValue,
            file: file,
          },
        ],
      });
    }
  };

  const handleDeleteDisplayPhotos = (event) => {
    event.preventDefault();
    const photoKey = event.target.id.split(":")[1];
    const newPhotos = props.business.photos.filter((item) => {
      return item.key !== photoKey;
    });

    props.setBusiness({
      ...props.business,
      photos: newPhotos,
    });
  };

  return (
    <div className="section">
      <div className="title2">Step Two</div>
      <div className="title3">
        Everything we need to put your business onto our 3D map
      </div>
      <div className="line" />
      <label htmlFor="name">name of business*</label> <br />
      <input
        type="text"
        name="name"
        className="inputText"
        value={props.business?.name}
        onChange={(e) => handleBusinessForm(e)}
      />
      <br />
      <label htmlFor="name">website</label> <br />
      <input
        type="text"
        name="website"
        className="inputText"
        value={props.business?.website}
        onChange={(e) => handleBusinessForm(e)}
      />
      <br />

      <label htmlFor="address">address where we place your virtual shop*</label>
      <br />
      {/* to do: on error show warning icon */}
      <div className="autocomplete-container">
        <input type="search" id="dawa-autocomplete-input" name="address" />
      </div>
      <br />
      <label htmlFor="businessCategoryId">business category</label>
      <br />
      <select
        name="businessCategoryId"
        className="inputText"
        onChange={(e) => handleBusinessForm(e)}
        value={props.business.businessCategoryId}
      >
        {props.businessCategories?.map((category, index) => (
          <option key={index + category.id} value={category.id}>
            {category.name}
          </option>
        ))}
      </select>
      <br />
      <label htmlFor="uploadLogo">upload logo *</label>
      <div>square size (width should be the same as height) </div>
      <div>Max size 128kb </div>
      <input
        type="file"
        accept=".jpg, .jpeg"
        name="uploadLogo"
        onChange={(e) => handleUploadLogo(e)}
      />
      <br />
      <label htmlFor="photos">upload business photos* (Max size 512kb)</label>
      {/* <img src={uploadIcon} /> */}
      <br />
      <input
        type="file"
        accept=".jpg, .jpeg"
        name="photos"
        onChange={(e) => handleUploadPhotos(e)}
      />
      <br />
      <div className="displayIcons">
        {props.business.photos?.map((item) => (
          <div key={item?.key}>
            <div className="iconRow">
              {item?.urlObject ? (
                <img
                  className="s3-display-image2"
                  src={item?.urlObject}
                  alt=""
                />
              ) : (
                <S3Image
                  level="protected"
                  className="s3-display-image2"
                  imgKey={item?.key}
                />
              )}
              <img
                src={xButton}
                alt=""
                className="xButton"
                id={"key:" + item?.key}
                onClick={(e) => handleDeleteDisplayPhotos(e)}
              />
            </div>
          </div>
        ))}
      </div>
      <br />
      <label htmlFor="body">about the business * (500 character max)</label>
      <br />
      <textarea
        name="about"
        maxLength="500"
        placeholder="Information about your business"
        value={props?.business?.about ?? ""}
        onChange={(e) => handleBusinessForm(e)}
      />
      <br />
      <BusinessOpeningHours
        business={props.business}
        setBusiness={props.setBusiness}
      />
    </div>
  );
};

export default Form2;
