import "./Section5.css";

const Section5 = () => (
  <div className="main-section-5">
    <div className="main-section-5-text">
      <p>
        Delivering up-to-date real time information to the people that matter
        most- your neighbors, your street, your city.
      </p>
      <p>
        There’s always two sides to every story, let us help you get yours heard
        today.
      </p>
    </div>
  </div>
);

export default Section5;
