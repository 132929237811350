import { useEffect } from "react";

import "./PublicPostView.css";
import getPost from "./../../../api/ThreadObjectGet";
import getUserInfo from "./../../../api/UserInfoGet";
import getLikes from "./../../../api/LikesGet";
import React, { useState } from "react";
import birdwireLogo from "../../../assets/logo.png";
import googlePlay from "../../../assets/googlePlay.png"
import appStore from "../../../assets/appStore.svg"
import PostView from "./PostView"

const PublicPostView = () => {
  const [post, setPost] = useState(
    {
      owner: "fad647e7-d493-49e9-bdce-2332cf0eb4bd",
      createdAt: "2021-07-30T19:41:28.685Z",
      updatedAt: "2021-07-30T19:41:28.685Z",
      photo: null,
      body: "loading...",
      longitude: 11.74961,
      latitude: 55.95155,
      threadType: "THREAD",
      anonymous: true,
      rootThreadName: "werwerwerwer",
      neighborhood: "nb_5595_1175",
      parentId: "(o.-)",
      parent: null,
  }
  );

  const [poster, setPoster] = useState(
    {
      "name": "loading..."
    }
  )

  const [reaction, setReaction] = useState(
    {
      "like": 0,
      "love": 0,
      "laugh": 0,
      "surprise": 0,
      "sad": 0,
      "angry": 0,
      "myReaction": 0
    }
  )

  const [error, setError] = useState(false);

  useEffect(async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('id');

      var response = await getPost(myParam);
      var post = response.data.getThreadObject;
      if (!post) {
        setError(true);
      } else {
        setPost(post);
      }
  }, []);

  useEffect(() => {
    updateUser();
    updateLikes();
  }, [post])

  const updateUser = async () => {
    if (post.owner) {
      var thePoster = (await getUserInfo(post.owner)).data;
      setPoster(thePoster);
    }
  }
  
  const updateLikes = async () => {
    var reactions = (await getLikes(post.id)).data
    console.log(reactions)
    setReaction(reactions)
  }


  return (
    <div>
      <div className="post-body" />
      <div className="post-body-limits">
        <div className="post-birdwireLogo">
          <img src={birdwireLogo} alt="" className="post-birdwireLogo"/>
        </div>

        {error ? 
        (
          <div className="post-error">Could not load the requested post</div>
        ) :
        (
          <PostView post={post} poster={poster} reaction={reaction}/>
        )}
        
        <div className="store-container">
          <div className="store-links">
            <a href="" className="store-play-store"><img src={googlePlay} className="store-download"/></a>
            <a href=""><img src={appStore} className="store-download"/></a>
          </div>
        </div>
      </div>
    </div>
  )
}
  
export default PublicPostView;