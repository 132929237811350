import { API } from "aws-amplify";
import { deleteDiscussionPrompt } from "../graphql/mutations";

const removeDiscussionPrompt = async (prompt) => {
  const deletedDiscussionPrompt = await API.graphql({
    query: deleteDiscussionPrompt,
    variables: { input: prompt },
  });

  return deletedDiscussionPrompt.deleteDiscussionPrompt;
};

export default removeDiscussionPrompt;
