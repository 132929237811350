import "./Form3.css";
import awsconfig from "../../../aws-exports";
import { S3Image } from "aws-amplify-react";
import xButton from "../../../assets/close-icon.svg";
import { API } from "aws-amplify";

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const Form3 = (props) => {
  const handleCreatePromotion = async function handleCreatePromotion(event) {
    event.preventDefault();
    var result = await API.post("birdwireCoupon", `/coupon/create`, {
      body: { businessId: props.business.id },
    });
    props.setPromotion({ ...result.data });
    alert("A new promotion is created");
  };

  const handleUnpublish = async function handleUnpublish(event) {
    event.preventDefault();
    console.log("unpublish", props.promotion);
    var result = await API.post("birdwireCoupon", `/coupon/publish-change`, {
      body: {
        id: props.promotion.id,
        collectable: !props.promotion.collectable,
      },
    });
    console.log(result);
    if (result.data) {
      props.setPromotion({ ...result.data });
      if (result.data.collectable) {
        alert("the promotion is collectable");
      } else {
        alert("the promotion is not collectable");
      }
    }
  };

  const handlePromotionForm = (event) => {
    props.setPromotion({
      ...props.promotion,
      [event.target.name]: event.target.value,
    });
  };

  const handleUploadPhotos = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 512000) {
        alert("Image is more than 0.5MB");
        return;
      }
      const eventValue = URL.createObjectURL(file);
      const photoId = uuidv4();
      const extension = "jpg";
      const key = `PromotionPhotos/${photoId}.${extension}`;

      props.setDisplayPhotos([
        ...props.displayPhotos,
        {
          urlObject: eventValue,
          file: file,
          key: key,
          extension: extension,
        },
      ]);

      props.setPromotion({
        ...props.promotion,
        photos: [
          ...props.promotion.photos,
          {
            region: awsconfig.aws_project_region,
            bucket: awsconfig.aws_user_files_s3_bucket,
            key: key,
            urlObject: eventValue,
          },
        ],
      });
    }
  };

  const handleDeleteDisplayPhotos = (event) => {
    event.preventDefault();
    const photoKey = event.target.id.split(":")[1];
    console.log(photoKey);
    const newDisplayPhotos = props.displayPhotos.filter((item) => {
      return item.key !== photoKey;
    });
    const newPhotos = props.promotion.photos.filter((item) => {
      return item.key !== photoKey;
    });

    props.setDisplayPhotos(newDisplayPhotos);

    props.setPromotion({
      ...props.promotion,
      photos: newPhotos,
    });
  };

  return (
    <div className="section">
      <div className="title2">Step Three - This step isn't mandatory </div>
      <div>
        <div className="title3" style={{ color: "#fff" }}>
          What discount do you want to offer?
        </div>
        <div className="line" />
      </div>
      <label htmlFor="displayPhotos">upload promotional photos* (Max size 512kb)</label>
      <br />
      <input
        type="file"
        accept=".jpg, .jpeg"
        onChange={(e) => handleUploadPhotos(e)}
        disabled={props.promotion.published}
        style={{
          backgroundColor: props.promotion.published ? "transparent" : "#fff",
        }}
      />
      <br />
      <div className="displayIcons">
        {props.promotion.photos?.map((item) => (
          <div key={item?.key}>
            <div className="iconRow">
              {item?.urlObject ? (
                <img
                  className="s3-display-image2"
                  src={item?.urlObject}
                  alt=""
                />
              ) : (
                <S3Image
                  level="protected"
                  className="s3-display-image2"
                  imgKey={item?.key}
                />
              )}
              {!props.promotion.published && (
                <img
                  src={xButton}
                  alt=""
                  className="xButton"
                  id={"key:" + item?.key}
                  onClick={(e) => handleDeleteDisplayPhotos(e)}
                />
              )}
            </div>
          </div>
        ))}
      </div>
      <br />
      <label htmlFor="header">promotional header* (30 character max)</label>
      <br />
      <input
        type="text"
        name="header"
        className="inputText"
        maxLength="30"
        value={props?.promotion?.header ?? ""}
        onChange={(e) => handlePromotionForm(e)}
        disabled={props.promotion.published}
      />
      <br />
      <label htmlFor="body">promotional text body* (500 character max)</label>
      <br />
      <textarea
        name="body"
        maxLength="500"
        placeholder="Use this space to talk about your promotion and any rules that may apply"
        value={props?.promotion?.body ?? ""}
        onChange={(e) => handlePromotionForm(e)}
        disabled={props.promotion.published}
      />

      <br />
      <label htmlFor="discountOffer">
        what discount would you like to offer?
      </label>
      <br />
      <input
        type="text"
        name="discountOffer"
        className="inputText"
        value={props.promotion?.discountOffer}
        onChange={(e) => handlePromotionForm(e)}
        disabled={props.promotion.published}
      />
      <br />
      <label htmlFor="expirationDate">
        expiration date of promotional coupon
      </label>
      <br />
      <input
        type="date"
        name="expirationDate"
        className="inputText"
        value={props.promotion?.expirationDate}
        onChange={(e) => handlePromotionForm(e)}
        disabled={props.promotion.published}
      />
      <div className="couponInlineDisplay">
        {props.promotion.active &&
          props.promotion.published &&
          props.promotion.collectable && (
            <div>
              <div>
                This coupon is published and collectable
                <button onClick={(e) => handleUnpublish(e)}>Disable</button>
              </div>
            </div>
          )}

        {props.promotion.active &&
          props.promotion.published &&
          !props.promotion.collectable && (
            <div className="couponInlineDisplay">
              <div>
                This coupon is published and non collectable
                <button onClick={(e) => handleUnpublish(e)}>Enable</button>
              </div>
            </div>
          )}
      </div>
      <div>
        {props.promotion.active && props.promotion.published && (
          <div className="couponInlineDisplay">
            <button
              disabled={!props.promotion.published}
              onClick={(e) => handleCreatePromotion(e)}
            >
              Create New
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Form3;
