import React, { useState } from "react";
import "./Form.css";
import Form1 from "./form1/Form1.js";
import Form2 from "./form2/Form2.js";
import Form3 from "./form3/Form3.js";
import PromotionCard from "../../components/previewCard/PreviewCard.js";
import PromotionBackCard from "../../components/previewCard/PreviewBackCard.js";
import updateUserBusiness from "../../api/BusinessUpdate";
import createUserBusiness from "../../api/BusinessCreate";
import uploadPhoto from "../../api/UploadPhoto";
import { API } from "aws-amplify";

const Form = (props) => {
  const [uploadLogo, setUploadLogo] = useState({
    urlObject: "",
    file: {},
    key: "",
    extension: "",
  });
  const [displayPhotos, setDisplayPhotos] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [cardFront, setCardFront] = useState(true);
  const [flipCardClassName, setFlipCardClassName] = useState("flip-card-inner");

  const handlePublish = async function handlePublish(event) {
    event.preventDefault();
    console.log("publish");
    if (window.confirm("Are you sure you want to publish?")) {
      await SaveAllData();
      var result = await API.post(
        "birdwireCoupon",
        `/coupon/publish/${props.promotion.id}`,
        {}
      );
      console.log(result);
      props.setPromotion({
        ...props.promotion,
        published: true,
        collectable: true,
      });
    } else {
      // todo: don't do the publish
    }
  };

  const handleSave = async function handleSave(event) {
    event.preventDefault();
    console.log("save");
    console.log(props);

    await SaveAllData();
    alert("Your business form is saved");
    if (!props.promotion.id) {
      window.location.reload();
    }
  };

  const SaveAllData = function SaveAllData() {
    // upload photo to s3
    return new Promise(async (resolve, reject) => {
      setDisableSubmit(true);

      for (let photo of displayPhotos) {
        await uploadPhoto(photo.key, photo.file);
      }
      setDisplayPhotos([]);

      for (let photo of props.business.photos) {
        if (photo.file) {
          await uploadPhoto(photo.key, photo.file);
          delete photo.file;
          delete photo.urlObject;
        }
      }
      await uploadPhoto(uploadLogo.key, uploadLogo.file);

      // updating business
      let data = {};
      var submitBusiness = { ...props.business };
      delete submitBusiness.category;
      delete submitBusiness.createdAt;
      delete submitBusiness.updatedAt;
      delete submitBusiness.logo.urlObject;
      var businessId;
      if (props.business.id) {
        console.log("updating existin business ", props.business);
        data = await updateUserBusiness(submitBusiness);
        businessId = props.business.id;
      } else {
        console.log(submitBusiness);
        delete submitBusiness.id;
        data = await createUserBusiness(submitBusiness);
        console.log("business created", data, data.data.createBusiness);
        props.setBusiness({
          ...props.business,
          id: data.data.createBusiness.id,
        });
        businessId = data.data.createBusiness.id;
      }
      console.log(data);

      // updating promotion
      // console.log(props.promotion);
      var submitPromotion = { ...props.promotion };
      if (!submitPromotion.businessId) {
        submitPromotion.businessId = businessId;
      }
      for (let photo of submitPromotion.photos) {
        console.log("removingurlobject from", photo);
        delete photo.urlObject;
      }
      var result = await API.post("birdwireCoupon", "/coupon", {
        body: submitPromotion,
      });
      console.log("promotion post result", result);
      setDisableSubmit(false);
      console.log("should enable", disableSubmit);
      resolve();
    });
  };

  const handleFlip = () => {
    var newCardFront = !cardFront;
    setCardFront(newCardFront);
    setFlipCardClassName(
      newCardFront ? "flip-card-inner" : "flip-card-inner-flipped"
    );
  };


  return (
    <div>
      <form>
        <div className="stepOne">
          <Form1 business={props.business} setBusiness={props.setBusiness} />
        </div>
        <div className="stepTwo">
          <Form2
            businessCategories={props.businessCategories}
            business={props.business}
            setBusiness={props.setBusiness}
            promotion={props.promotion}
            setPromotion={props.setPromotion}
            uploadLogo={uploadLogo}
            setUploadLogo={setUploadLogo}
            displayPhotos={displayPhotos}
            setDisplayPhotos={setDisplayPhotos}
          />
        </div>
        <div className="stepThree">
          <Form3
            businessCategories={props.businessCategories}
            business={props.business}
            setBusiness={props.setBusiness}
            promotion={props.promotion}
            setPromotion={props.setPromotion}
            uploadLogo={uploadLogo}
            setUploadLogo={setUploadLogo}
            displayPhotos={displayPhotos}
            setDisplayPhotos={setDisplayPhotos}
          />
        </div>
      </form>
      <div className="stepOne">
        <div>
          <div style={{ minHeight: "850px" }}>
            <div className="buttonParent">
              <button onClick={handleFlip} className="flipButton">
                {cardFront
                  ? "Click to see backside of the card"
                  : "Click to see frontside of the card"}
              </button>
            </div>
            <div className="flip-card">
              <div className={flipCardClassName}>
                <div className="flip-card-front">
                  <PromotionCard
                    business={props.business}
                    promotion={props.promotion}
                    uploadLogo={uploadLogo}
                    displayPhotos={displayPhotos}
                  />
                </div>
                <div className="flip-card-back">
                  <PromotionBackCard
                    business={props.business}
                    promotion={props.promotion}
                    uploadLogo={uploadLogo}
                    displayPhotos={displayPhotos}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buttonParent">
          <button
            disabled={disableSubmit}
            onClick={(e) => handleSave(e)}
            className="saveButton"
          >
            Save
          </button>
          <button
            disabled={
              !props.promotion.id || props.promotion.published || disableSubmit
            }
            onClick={(e) => handlePublish(e)}
            className="publishButton"
          >
            Publish
          </button>
        </div>
        {/* <div>
          <button onClick={(e) => Test(e)}>Test</button>
        </div> */}
      </div>
    </div>
  );
};

export default Form;
